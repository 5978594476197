import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { List, ListItem, ListItemIcon, ListItemText, Drawer, Divider, Box, Typography, Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppleIcon from '@mui/icons-material/Apple';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import FlightIcon from '@mui/icons-material/Flight';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

const drawerWidth = 340;

const theme = createTheme({
  palette: {
    mode: 'dark', // Switches the theme to dark mode
  },
});

const SidebarContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2), // Add padding to create space around the sidebar
}));

const SidebarDrawer = styled(Drawer)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  position: "absolute",
  top: theme.spacing(2), // Add space from the top
  left: open ? theme.spacing(2) : -drawerWidth, // Add space from the left and adjust for open/close
  zIndex: 10,
  borderRadius: theme.shape.borderRadius * 2, // Apply border radius for rounded corners
  boxShadow: theme.shadows[5], // Add shadow for floating effect
  transition: theme.transitions.create('left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [`& .MuiDrawer-paper`]: {
    width: drawerWidth,
    boxSizing: "border-box",
    borderRadius: theme.shape.borderRadius * 2, // Apply border radius for rounded corners
    backgroundColor: theme.palette.background.default, // Use theme background color or replace with a custom color
    color: theme.palette.text.primary, // Text color for items in the drawer
    boxShadow: theme.shadows[5], // Add shadow for floating effect
  },
}));

const Sidebar = ({ onSelectDataSource, open }) => {
  const user = {
    name: "Demo Acct",
    email: "demo@admin.net",
  };

  const handleItemClick = (source) => {
    onSelectDataSource(source);
  };

  return (
    <ThemeProvider theme={theme}>
      <SidebarContainer>
        <SidebarDrawer variant="persistent" open={open}>
          <Box display="flex" flexDirection="column" alignItems="center" p={2}>
            <Avatar alt={user.name} src="/static/images/avatar/1.jpg" />
            <Typography variant="h6">{user.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {user.email}
            </Typography>
          </Box>
          <Divider />
          <List>
            <ListItem button onClick={() => handleItemClick("apple")}>
              <ListItemIcon>
                <AppleIcon />
              </ListItemIcon>
              <ListItemText primary="Apple" />
            </ListItem>
            <ListItem button onClick={() => handleItemClick("fastfood")}>
              <ListItemIcon>
                <FastfoodIcon />
              </ListItemIcon>
              <ListItemText primary="Food Inc" />
            </ListItem>
            <ListItem button onClick={() => handleItemClick("airlines")}>
              <ListItemIcon>
                <FlightIcon />
              </ListItemIcon>
              <ListItemText primary="Airlines" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </SidebarDrawer>
      </SidebarContainer>
    </ThemeProvider>
  );
};

export default Sidebar;
