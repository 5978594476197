import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import * as d3 from 'd3';
import './index.css';

import Sidebar from './components/Sidebar';
import TopStats from './components/TopStats';
import PieChart from './components/PieChart';
import WorldMap from './components/WorldMap';
import BarChart from './components/BarChart';
import Sunburst from './components/Sunburst';
import RadialTree from './components/RadialTree';
import CalendarRuler from './components/CalendarRuler';
import ReactiveTable from './components/ReactiveTable';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from "@mui/material/styles";

const launches = [
  { stateId: 'US' },
  { stateId: 'RU' },
  { stateId: 'CN' },
  { stateId: 'US' },
  { stateId: 'SU' },
  { stateId: 'CN' },
  { stateId: 'Other' },
];

const AppContainer = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative", 
}));

const MainContent = styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  zIndex: 1, 
}));


function App() {
  const [pieChartData, setPieChartData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [radialTreeData, setRadialTreeData] = useState(null);
  const [subBurstData, setSubBurstData] = useState(null);
  const [dataSource, setDataSource] = useState('apple');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const iframeSrc_1 = "https://observablehq.com/embed/ffef55059de3ba4b@520?cells=apple&api_key=05e1fb6fc65d71336c37da63f6ceddc96ce6eb47";
  const iframeSrc_2 = "https://observablehq.com/embed/c934753e68aa5231@25?cells=baby_names&api_key=56a3298514884f50cdf618e66290336882a3cd67"

  const loadData = (source) => {
    Papa.parse(`./data/${source}/product_filtered_${source}.csv`, {
      download: true,
      header: true,
      complete: function(results) {
        setPieChartData(results.data);
        setIsDataLoaded(true);
      }
    });

    d3.json(`./data/${source}/radial_tree.json`).then(data => {
      setRadialTreeData(data);
    });

    d3.json(`./data/${source}/sunburst.json`).then(data => {
      setSubBurstData(data);
    });
  };

  useEffect(() => {
    loadData(dataSource);
  }, [dataSource]);

  useEffect(() => {
    if (isDataLoaded) {
      const uniqueParts = new Set();
      const uniqueIphoneVersions = new Set();


      /*
        .filter(d => d.iphone_version !== "unknown")
        .filter(d => d.iphone_version !== "")
        .filter(d => d.iphone_version !== undefined)
        .filter(d => d.part !== "")
        .filter(d => d.part !== undefined)
      */ 
     
      const filtered = pieChartData
        .filter(d => d.iphone_version && 
                d.iphone_version !== "unknown" && 
                d.part)
        .map(d => {
          uniqueParts.add(d.part);
          uniqueIphoneVersions.add(d.iphone_version);
          return d;
        });

      setBarChartData(filtered);
      console.log("Filtered Data", filtered);
      console.log("Unique Parts:", Array.from(uniqueParts));
      console.log("Unique iPhone Versions:", Array.from(uniqueIphoneVersions));
    }
  }, [isDataLoaded, pieChartData]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <AppContainer>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={toggleSidebar}
        edge="start"
        sx={{ 
          position: 'fixed', // Position fixed to the viewport
          top: 20, // Adjust top as needed
          left: 20, // Adjust left as needed
          zIndex: 1200, // Ensure it stays above the sidebar
          width: 100, 
          height: 100
        }}>
        <MenuIcon />
      </IconButton>
      <Sidebar onSelectDataSource={setDataSource} open={sidebarOpen} />
      <MainContent open={sidebarOpen}>
        <TopStats launches={launches} />
        <CalendarRuler />
        <WorldMap />
        {radialTreeData ? <RadialTree radial_tree_data={radialTreeData} /> : 'Loading data...'}
        <div className="chart-container">
          <div className="chart-item">
            {isDataLoaded && pieChartData.length > 0 ? 
              (<PieChart data={pieChartData} column="product" />) : 
              (<div>Loading data...</div>)
            }
          </div>
          <div className="chart-item">
            <BarChart data={barChartData} />
          </div>         
          {subBurstData ? <Sunburst subBurstData={subBurstData} /> : 'Loading data...'}
        </div>
        <ReactiveTable src={iframeSrc_1} />
        <ReactiveTable src={iframeSrc_2} />
      </MainContent>
    </AppContainer>
  );
}

export default App;
