import React from 'react';
import PropTypes from 'prop-types';

const ReactiveTable = ({ src }) => {
  return (
    <iframe 
      frameBorder="0"
      scrolling="no"
      className="card reactive-table" 
      src={src}>
    </iframe>
  );
}

ReactiveTable.propTypes = {
  src: PropTypes.string.isRequired,
};

export default ReactiveTable;
