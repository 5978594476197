import React, { useState, useEffect, useRef } from 'react';
import * as Plot from '@observablehq/plot';

const BarChart = ({ data }) => {
  const [userInput, setUserInput] = useState('None');
  const chartRef = useRef(null);

  const handleChange = (event) => {
    setUserInput(event.target.value);
  };

  useEffect(() => {
    if (userInput !== 'None' && chartRef.current) {
      const chart = createBar(data, userInput);
      chartRef.current.innerHTML = '';
      chartRef.current.appendChild(chart);
    }
  }, [userInput, data]);

  const createBar = (data, userInput) => {
    return Plot.plot({
      x: {
        label: "",
        tickRotate: -90,
      },
      margin: 75,

      marks: [
        Plot.frame({
          stroke: "white",
          anchor: "bottom",
          opacity: 0.9
        }),
        Plot.barY(
          data,
          Plot.groupX(
            { y: "count" },
            {
              x: "part",
              sort: { x: "y", reverse: true, limit: 10 },
              fill: d => d.part,
              tip: d => `<span style="color: black;"> Frequency: ${d.count}</span>`,
              fy: userInput === 'global' ? null : 'iphone_version'
            },
          )
        ),
      ],
      color: { scheme: "Observable10" }
    });
  };

  return (
    <div className="bar-chart-container card fade-in">
      <label htmlFor="view-select">Choose View: </label>
      <select id="view-select" value={userInput} onChange={handleChange}>
        <option value="None">None</option>
        <option value="global">Global</option>
        <option value="by_version">By Version</option>
      </select>
      {userInput !== 'None' && (
        <div ref={chartRef}></div>
      )}
    </div>
  );
};

export default BarChart;
