import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import * as topojson from 'topojson-client';
import * as Plot from '@observablehq/plot'; 

const WorldMap = () => {
  const [data, setData] = useState(null);
  const [land, setLand] = useState(null);
  const mapRef = useRef(null);

  useEffect(() => {
    // Load the meteorite data
    d3.csv('./data/Meteorite_Landings.csv', d3.autoType).then((data) => {
      console.log('Meteorite data loaded:', data); // Logging the data
      setData(data);
    });

    // Load the world topology data
    d3.json('./data/countries-110m.json').then((world) => {
      console.log('World topology data loaded:', world); // Logging the data
      const land = topojson.feature(world, world.objects.land);
      setLand(land);
    });
  }, []);

  useEffect(() => {
    if (data && land && mapRef.current) {
      const map = Plot.plot({
        projection: "equal-earth",
        margin:0,
        width: 1200,
        height: 600,
        marks: [
          //Plot.graticule(),
          Plot.geo(land, { fill: "#BAB0AC"}),
          Plot.dot(data, { x: "reclong", 
                           y: "reclat", 
                           r: "mass (g)", 
                           fill: "purple", 
                           opacity: 0.5, 
                           tip: true })
        ],
        r: { range: [1, 10] }
      });
      mapRef.current.innerHTML = ''; // Clear any previous content
      mapRef.current.appendChild(map);
      console.log('Map rendered');
    }
  }, [data, land]);

  return (
    <div className="map_container">
      <div id="projection_map" className="card fade-in" ref={mapRef}></div>
    </div>
  );
};

export default WorldMap;
