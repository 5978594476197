import React from 'react';

class CalendarRuler extends React.Component {
  componentDidMount() {
    const slider = document.querySelector('.slider');
    const calendarRuler = document.querySelector('.calendar-ruler');

    calendarRuler.addEventListener('click', function (e) {
      let rect = calendarRuler.getBoundingClientRect();
      let offsetX = e.clientX - rect.left;

      if (offsetX >= 0 && offsetX <= rect.width - slider.offsetWidth) {
        slider.style.left = offsetX + 'px';
      }
    });
  }

  render() {
    return (
      <div className="calendar-ruler">
        <div className="month-line past" id="jan">
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          Jan
        </div>
        <div className="month-line past" id="feb">
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          Feb
        </div>
        <div className="month-line past" id="mar">
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          Mar
        </div>
        <div className="month-line past" id="apr">
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          Apr
        </div>
        <div className="month-line current" id="may">
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          May
        </div>
        <div className="month-line future" id="jun">
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          Jun
        </div>
        <div className="month-line future" id="jul">
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          Jul
        </div>
        <div className="month-line future" id="aug">
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          Aug
        </div>
        <div className="month-line future" id="sep">
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          Sep
        </div>
        <div className="month-line future" id="oct">
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          Oct
        </div>
        <div className="month-line future" id="nov">
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          Nov
        </div>
        <div className="month-line future" id="dec">
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          <div className="week-line"></div>
          Dec
        </div>
        <div className="slider"></div>
      </div>
    );
  }
}

export default CalendarRuler;
