import React from 'react';

const TopStats = ({ launches }) => {
  const stats = [
    {
      id: 'US',
      name: 'United States 🇺🇸',
      count: launches.filter((d) => d.stateId === 'US').length,
    },
    {
      id: 'RU',
      name: 'Russia 🇷🇺 ',
      count: launches.filter((d) => d.stateId === 'SU' || d.stateId === 'RU').length,
    },
    {
      id: 'CN',
      name: 'China 🇨🇳',
      count: launches.filter((d) => d.stateId === 'CN').length,
    },
    {
      id: 'Other',
      name: 'Other',
      count: launches.filter((d) => !['US', 'SU', 'RU', 'CN'].includes(d.stateId)).length,
    },
  ];

  return (
    <div className="grid grid-cols-4">
      {stats.map((stat) => (
        <div className="card" key={stat.id}>
          <h2>{stat.name}</h2>
          <span className="big">{stat.count.toLocaleString('en-US')}</span>
        </div>
      ))}
    </div>
  );
};

export default TopStats;
