import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const PieChart = ({ data, column }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data && column) {
      // Clear previous chart content
      d3.select(chartRef.current).selectAll('*').remove();
      
      // Create the pie chart and append it to the ref
      const svgNode = create_pie_chart(data, column);
      chartRef.current.appendChild(svgNode);
    }
  }, [data, column]);

  function create_pie_chart(data, column) {
    console.log("Data:", data); // Log the overall data structure
    console.log("Column exists:", column in data[0]); // Check for column name
    console.log("First value's type", typeof data[0][column]);

    // Specify the chart’s dimensions.
    const width = 500;
    const height = Math.min(width, 500);

    // Create the color scale. Note: domain will need updating if 'iphone_version' has many unique values
    const color = d3.scaleOrdinal()
      .domain(data.map(d => d[column])) 
      .range(d3.schemeObservable10)

    // Create the pie layout and arc generator.
    const pie = d3.pie()
      .sort(null)
      .value(d => d.value); // Assuming 'value' column exists or you'll need to calculate counts

    const arc = d3.arc()
      .innerRadius(width / 4)
      .outerRadius(Math.min(width, height) / 2 - 1);

    const labelRadius = arc.outerRadius()() * 0.8;

    // A separate arc generator for labels.
    const arcLabel = d3.arc()
      .innerRadius(labelRadius)
      .outerRadius(labelRadius);

    // Data Calculation (with logging)
    let pieChartData = d3.rollup(data, v => v.length, d => d[column]); 
    pieChartData = Array.from(pieChartData, ([columnValue, value]) => ({ name: columnValue, value }));
    console.log("Pie Chart Data:", pieChartData); // Inspect this output

    const arcs = pie(pieChartData); // Update data for pie chart generation

    // Create the SVG container.
    const svg = d3.create("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [-width / 2, -height / 2, width, height])
      .attr("style", "max-width: 100%; height: auto; font: 10px sans-serif;");

    // Add a sector path for each value.
    svg.append("g")
      .attr("stroke", "white")
      .selectAll("path")
      .data(arcs) 
      .join("path")
      .attr("fill", d => color(d.data.name))
      .attr("d", arc)
      .append("title")
      .text(d => `${d.data.name}: ${d.data.value.toLocaleString("en-US")}`)
      .on("mouseover", function(event, d) { // Add mouseover event handler
        d3.select(this).style("opacity", 0.8); // Highlight on hover

        // Create tooltip element
        const tooltip = d3.select("body") 
          .append("div")
          .attr("class", "tooltip")
          .style("position", "absolute")
          .style("opacity", 0);

        tooltip.html(`${d.data.name}: ${d.data.value.toLocaleString("en-US")}`)
          .style("left", (event.pageX + 10) + "px")
          .style("top", (event.pageY - 15) + "px")
          .transition()
          .duration(200)
          .style("opacity", 0.9);
      })
      .on("mouseout", function() { // Add mouseout event handler
        d3.select(this).style("opacity", 1);  // Reset opacity
        d3.selectAll(".tooltip").remove();  // Remove tooltip
      });

    // Create a new arc generator to place a label close to the edge.
    // The label shows the value if there is enough room.
    svg.append("g")
      .attr("text-anchor", "middle")
      .selectAll("text")
      .data(arcs)
      .join("text")
      .attr("transform", d => `translate(${arcLabel.centroid(d)})`)
      .call(text => text.append("tspan")
        .attr("y", "-0.4em")
        .attr("font-weight", "bold")
        .text(d => d.data.name))
      .call(text => text.filter(d => (d.endAngle - d.startAngle) > 0.25).append("tspan")
        .attr("x", 0)
        .attr("y", "0.7em")
        .attr("fill-opacity", 0.7)
        .text(d => d.data.value.toLocaleString("en-US")));

    return svg.node();
  }

  return (
    <div className="pie_container">
      <div className="card fade-in" ref={chartRef}></div>
    </div>
  );
};

export default PieChart;
