import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const RadialTree = ({ radial_tree_data }) => {
  const [currentLevel, setCurrentLevel] = useState(0);
  const chartRef = useRef(null);

  useEffect(() => {
    if (radial_tree_data) {
      const svgElement = createRadialTree(radial_tree_data, currentLevel);
      chartRef.current.innerHTML = ''; // Clear previous chart
      chartRef.current.appendChild(svgElement);
    }
  }, [radial_tree_data, currentLevel]);

  function createRadialTree(data, currentLevel) {
    if (!data) return;

    // Specify the chart’s dimensions.
    const width = 600;
    const height = width;
    const cx = width * 0.5;
    const cy = height * 0.54;
    const radius = Math.min(width, height) / 2 - 80;

    // Create a radial cluster layout.
    const tree = d3.cluster()
      .size([2 * Math.PI, radius])
      .separation((a, b) => (a.parent === b.parent ? 1 : 2) / a.depth);

    // Sort the tree and apply the layout.
    const root = tree(d3.hierarchy(data)
      .sort((a, b) => d3.ascending(a.data.name, b.data.name)));

    // Create a color scale
    const color = d3.scaleOrdinal(d3.schemeTableau10);

    // Creates the SVG container.
    const svg = d3.create("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [-cx, -cy, width, height])
      .attr("style", "width: 100%; height: auto; font: 10px sans-serif;");

    // Append links.
    svg.append("g")
      .attr("fill", "none")
      .attr("stroke", "#555")
      .attr("stroke-opacity", 0.4)
      .attr("stroke-width", 1.5)
      .selectAll("path")
      .data(root.links())
      .join("path")
      .attr("d", d3.linkRadial()
        .angle(d => d.x)
        .radius(d => d.y))
      .attr("opacity", d => (d.target.depth < currentLevel ? 1 : 0))
      .transition()
      .duration(2000)
      .attr("opacity", d => (d.target.depth <= currentLevel ? 1 : 0));

    // Append nodes.
    svg.append("g")
      .selectAll("circle")
      .data(root.descendants())
      .join("circle")
      .attr("transform", d => `rotate(${d.x * 180 / Math.PI - 90}) translate(${d.y},0)`)
      .attr("fill", d => d.children ? "#9441ee" : "#9441ee")
      .attr("r", 2.5)
      .attr("opacity", d => (d.depth < currentLevel ? 1 : 0))
      .transition()
      .duration(2000)
      .attr("opacity", d => (d.depth <= currentLevel ? 1 : 0));

    // Append labels.
    svg.append("g")
      .attr("stroke-linejoin", "round")
      .attr("stroke-width", 0.1)
      .selectAll("text")
      .data(root.descendants())
      .join("text")
      .attr("transform", d => `rotate(${d.x * 180 / Math.PI - 90}) translate(${d.y},0) rotate(${d.x >= Math.PI ? 180 : 0})`)
      .attr("dy", "0.31em")
      .attr("x", d => d.x < Math.PI === !d.children ? 6 : -6)
      .attr("text-anchor", d => d.x < Math.PI === !d.children ? "start" : "end")
      .attr("paint-order", "stroke")
      .attr("stroke", "white")
      .attr("fill", d => {
        if (!d.children) {
          return color(d.parent.data.name);
        }
        return "currentColor";
      })
      .attr("opacity", d => (d.depth < currentLevel ? 1 : 0))
      .transition()
      .duration(2000)
      .attr("opacity", d => (d.depth <= currentLevel ? 1 : 0))
      .text(d => d.data.name);

    return svg.node();
  }

  const handleDivClick = () => {
    setCurrentLevel(prevLevel => {
      if (prevLevel === d3.hierarchy(radial_tree_data).height) {
        return 0;
      }
      return prevLevel + 1;
    });
  };

  return <div ref={chartRef} className="radial-tree-container card" onClick={handleDivClick}></div>;
};

export default RadialTree;
